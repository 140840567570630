<template>
  <b-row>
    <b-col class="nopadding" cols="12" xl="3">
      <b-card>
        <div slot="header">
          <strong># {{$t('car.form_title')}}</strong>
        </div>
        <div class="text-center">
          <img v-if="car&&car.logo" :src="computed_logo(car.logo)"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <img v-else src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <h6>{{$t("common.choose_another_image")}}</h6>
          <input type="file" class="text-center center-block file-upload"
          @change="getImage" id="fileControl">
        </div>
        <br>
        <div class="row">
          <div class="col bg-secondary">
            <b-form-group>
              <label for="txt_car_name">{{$t('car.car_name')}}</label>
              <div v-if="$v.car.name.$error" class="pull-right">
                <div class="text-danger" v-if="!$v.car.name.required">{{$t('car.car_required')}}</div>
              </div>
              <b-form-input type="text" id="txt_car_name" :placeholder="$t('car.car_placeholder')"
              v-model="car.name" maxlength="50" ref="txt_car_name" size="sm"
              v-on:input="$v.car.name.$touch"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="txt_car_numer">{{$t('car.number')}}</label>
              <div v-if="$v.car.number.$error" class="pull-right">
                <div class="text-danger" v-if="!$v.car.number.required">{{$t('car.number_required')}}</div>
              </div>
              <b-form-input type="text" id="txt_car_number" :placeholder="$t('car.number_placeholder')"
              v-model="car.number" maxlength="15" size="sm"
              v-on:input="$v.car.number.$touch"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="txt_num_people">{{$t('car.people')}}</label>
              <b-form-input type="number" id="txt_num_people" :placeholder="$t('car.people_placeholder')"
              v-model="car.people" maxlength="10" size="sm"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="txt_weight">{{$t('car.weight')}}</label>
              <div v-if="$v.car.weight.$error" class="pull-right">
                <div class="text-danger" v-if="!$v.car.weight.required">{{$t('car.weight_required')}}</div>
              </div>
              <b-form-input type="text" id="txt_weight" :placeholder="$t('car.weight_placeholder')"
              v-model="car.weight" maxlength="10" size="sm"
              v-on:input="$v.car.weight.$touch"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="txt_height">{{$t('car.height')}} (m)</label>
              <b-form-input type="text" id="txt_height" :placeholder="$t('car.height_placeholder')"
              v-model="car.height" maxlength="50" size="sm"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="txt_car_owner">{{$t('car.owner')}}</label>
              <b-form-input type="text" id="txt_car_owner" :placeholder="$t('car.owner_placeholder')"
              v-model="car.owner" maxlength="50" size="sm"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="txt_des">{{$t('car.description')}}</label>
              <b-form-textarea type="text" id="txt_des"
              v-model="car.des" maxlength="200"
              :placeholder="$t('car.description_placeholder')"></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <div class="row pull-right bt-2">
          <b-button class="mt-1" variant="outline-success" size="sm"
          @click.prevent="clear()"><i class="fa fa-refresh"></i> {{$t('common.button.refresh')}}</b-button>
          <b-button class="ml-2 mt-1" variant="outline-primary" size="sm" @click.prevent="saveCar()"><span class="icon is-small">
              <i class="fa fa-check"></i> {{$t('common.button.save')}}</span></b-button>
        </div>
      </b-card>
    </b-col>
    <b-col class="nopadding" cols="12" xl="9">
      <transition name="slide">
      <b-card>
        <div slot="header"><strong><i class="fa fa-list" aria-hidden="true"></i> {{$t('car.table_title')}}</strong></div>
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <input type="text" class="form-control" :placeholder="$t('common.search_text')"
            v-model="filter" maxlenght="50" @keyup.enter="apply_filter">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter"
                type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
            </div>
          </div>
        </b-form-group>
        <b-table striped hover responsive :tbody-tr-class="rowDeleted"
        :items="filteredAndSortedData" :fields="fields" :current-page="page"
        :per-page="0" @row-clicked="rowClicked">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
          <template slot="updated_at" slot-scope="data">
            <label v-if="data.item.updated_at">{{VnDateTimeFormat(data.item.updated_at)}}</label>
            <label v-else></label>
          </template>
          <template slot="created_at" slot-scope="data">
            <label>{{VnDateTimeFormat(data.item.created_at)}}</label>
          </template>
          <template slot="status" slot-scope="data">
            <b-badge :variant="getBadge(data.item.status)">{{getObjectStatus(data.item.status)}}</b-badge>
          </template>
          <template slot="branch" slot-scope="data">
            <label v-if="data.item.branch">{{data.item.branch.name}}</label>
            <label v-else>{{data.item.company.name}}</label>
          </template>
          <template slot="action" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="outline-danger"
              v-if="data.item.status>-1"
                @click="confirmDelete(data.item)">
                <i class="fa fa-trash"></i> {{$t('common.button.delete')}}
              </b-button>
              <b-button variant="outline-success" v-else
                @click="recoverObj(data.item)">
                <i class="fa fa-recycle"></i> {{$t('common.button.recover')}}
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <template slot="footer">
            <b-row class="pull-right">
              <b-col>
                <nav>
                  <pager :frame="8"
                        :pageCount="page_count"
                        :page="page"
                        @change="change_page">
                  </pager>
                </nav>
              </b-col>
            </b-row>
          </template>
      </b-card>
      </transition>
    </b-col>
    <b-modal id="viewModal" :title="$t('common.message')" header-bg-variant="info" no-body ok-only>
      <div class="h3">{{$t('common.image_error')}}</div>
    </b-modal>
  </b-row>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
</style>
<script>
import { validationMixin } from 'vuelidate'
import { required,requiredIf, minLength,maxLength,email,url,numeric }
from 'vuelidate/lib/validators'
import moment from 'moment'
import Pager from '../Pager'
import { mapGetters, mapActions } from 'vuex'
import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
import { Role } from '@/helpers/role.js'
import { uuid } from 'vue-uuid'

export default {
  name: 'car',
  mixins: [validationMixin],
  components: {Pager},
  props: {
    hover: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      processing:false,
      options_business: [],
      car:{
        id:null,
        logo:null,
        code:uuid.v4(),
        name:null,
        owner:null,
        number:null,
        weight:null,
        height:null,
        people:null,
        branch_id:null,
        company_id:null,
        user_create_id:null,
        user_update_id:null,
        des:null
      }
    }
  },
  validations: {
    car: {
      name:{
        required
      },
      number:{
        required
      },
      weight:{
        required
      }
    }
  },
  computed: {
    ...mapGetters('st_car', ['page_count','page','filter','per_page']),
    fields(){
      let fields=[]
      let user=this.$store.state.st_authentication.user
      if(user.role==Role.Super_Admin){
        fields.push({key: 'company',label:this.$t('common.company'),sortable: true, variant:'info'})
        fields.push({key: 'branch',label:this.$t('common.branch'),sortable: true})
        fields.push({key: 'business',label:this.$t('common.business'),sortable: true})
      }

      if(user.role==Role.Admin && user.company.cocon){
        fields.push({key: 'branch',label:this.$t('common.company_branch'),sortable: true, variant:'info'})
      }

      fields.push({key: 'owner',label:this.$t('car.owner'),sortable: true, variant:'info'})
      fields.push({key: 'name',label:this.$t('car.car_name'),sortable: true})
      fields.push({key: 'number',label:this.$t('car.number'),sortable: true})
      fields.push({key: 'people',label:this.$t('car.people'),sortable: true})
      fields.push({key: 'weight',label:this.$t('car.weight'),sortable: true})
      fields.push({key: 'height',label:this.$t('car.height') +"(m)",sortable: true})
      fields.push({key: 'des',label:this.$t('car.description'),sortable: true})
      fields.push({key: 'created_at',label:this.$t('common.created_at'),sortable: true})
      fields.push({key: 'status',label:this.$t('common.status'),sortable: true})

      if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
        fields.push({key: 'action',label:'',sortable: false})
      }

      return fields
    },
    ls_unit_parent_ddl(){
      return this.$store.state.st_unit.ls_unit_parent_ddl
    },
    ls_unit_child_ddl(){
      return this.$store.state.st_unit.ls_unit_child_ddl
    },
    ls_product_ddl(){
      return this.$store.state.st_car.ls_product_ddl
    },
    user_login() {
      return this.$store.state.st_authentication.user
    },
    filter:{
      get: function(){
        return this.$store.state.st_car.filter
      },
      set: function(text){
        this.$store.commit('st_car/set_filter',text)
      }
    },
    filteredAndSortedData:{
      get: function(){
        let vm=this
        let result = vm.$store.state.st_car.ls_car
        if (vm.filter) {
            result = result.filter(item =>vm.searchLike(item))
        }

        return result
      }
    }
  },
  methods: {
    ...mapActions('st_car', ['get_ls_car','change_page', 'apply_filter']),
    clear(){
      this.car={id:null,name:null,owner:null,des:null,weight:null,company_id:null,branch_id:null,number:null,people:0,
      user_create_id:null,user_update_id:null,height:null,code:uuid.v4(),logo:null}
      this.$v.$reset()
    },
    rowDeleted(item, type){
      if (!item || type !== 'row') return
      if (item.status === -1) return 'table-danger'
    },
    _validations () {
      return new Promise(resolve => {
        if (this.$v.$error || !this.$v.$pending) {
            return resolve()
        }
        let poll = setInterval(() => {
        if (!this.$v.$pending) {
            clearInterval(poll)
            resolve()
        }
        }, 200)
      })
    },
    async isValid () {
      this.$v.$reset()
      this.$v.$touch()
      await this._validations()
      return Promise.resolve(!this.$v.$error)
    },

    async saveCar() {
      const isValid = await this.isValid()
      if (!isValid) {
        return
      }

      var app = this
      if (app.processing == true) {
        return
      }

      // set the async state
      app.processing = true

      if(app.car.id){
        app.$store.dispatch('st_car/update',
        {id:app.car.id,product_id:app.car.product_id,logo:app.car.logo,
        des:app.car.des,user_update_id:app.user_login.id,height:app.car.height,
        number:app.car.number,people:app.car.people,owner:app.car.owner,weight:app.car.weight})
        .then(function (resp) {
          debugger
          app.get_ls_car()
          app.clear()
          app.$toastr.success(app.$t("common.updated_success"),app.$t("common.message"))
          app.processing = false
        })
        .catch(function (resp) {
          debugger
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          app.processing = false
        })
      }else{
        debugger
        app.car.user_create_id=app.user_login.id
        app.car.company_id=app.user_login.company_id
        app.car.branch_id=app.user_login.branch_id
        app.car.business=app.user_login.company.business

        app.$store.dispatch('st_car/create',app.car)
        .then(function (resp) {
          app.get_ls_car()
          app.clear()
          app.$toastr.success(app.$t("common.created_success"),app.$t("common.message"))
          app.processing = false
        })
        .catch(function (resp) {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          app.processing = false
        })
      }
      app.processing = false
    },
    getRowCount (items) {
      return items.length
    },
    rowClicked (item) {
      if(!item) return
      this.car=item
    },
    searchLike:function(item){
      debugger
      return item.name.includes(this.filter)
      ||item.number.toString().includes(this.filter)
      ||item.weight&&item.weight.toString().includes(this.filter)
      ||item.height&&item.height.toString().includes(this.filter)
      ||item.des&&item.des.includes(this.filter)
      ||item.branch&&item.branch.name.includes(this.filter)
      ||item.company&&item.company.name.includes(this.filter)
      ||item.owner&&item.owner.includes(this.filter)
      ||item.people&&item.people.toString().includes(this.filter)
    },
    confirmDelete(obj) {
      this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then(value => {
        if(value!=true) return
        this.deleteObj(obj)
      })
      .catch(err => {
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      })
    },
    async recoverObj(obj){
      let app=this
      if(obj.status>=0) return
      let co=await this.$store.dispatch('st_car/recover_obj',obj.id)
      if(co && co.data.ok){
        this.get_ls_car()
        app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      }
    },
    getImage:function(e){
      let app=this
      let image=e.target.files[0]
      if(image.size>102400){
        app.$bvModal.show("viewModal")
        document.getElementById("fileControl").value = ""
        return
      }
      let reader=new FileReader()
      reader.readAsDataURL(image)
      reader.onload=e=>{
        app.car.logo=e.target.result
      }
    },
    async deleteObj(obj) {
      let app=this
      if(obj.status<0) return
      if(obj.status==1){
        app.$toastr.info(app.$t("common.item_using_message",{obj:app.$t("common.car")}),app.$t("common.message"))
        return
      }
      let co=await this.$store.dispatch('st_car/delete_obj',obj.id)
      if(co && co.data.ok){
        this.get_ls_car()
        app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      }
    }
  },
  mounted () {
    let vm=this
    vm.get_ls_car()
  }
}
</script>
